import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';



import data from "./PropertyData.json";
import Property from './Property';

function PropertySlider(){
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
      slidesToSlide: 1
    },
    desktop: {
      breakpoint: { max: 3000, min: 1000 },
      items: 2.5,
      slidesToSlide: 2
    },
    desktop1: {
      breakpoint: { max: 1000, min: 768 },
      items: 1.5,
      slidesToSlide: 2
    },
    tablet: {
      breakpoint: { max: 768, min: 464 },
      items: 1.5,
      slidesToSlide: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1
    }
  };

  const Product = data.map((item) =>
    <Property 
     name={item.name}
     type={item.type} 
     image={item.image}
     price={item.price} 
     beds={item.beds}
     baths={item.baths} 
     Type={item.Type}
     sqft={item.sqft} 
     location={item.location}
     Price={item.Price}
     POR={item.POR}
     EnquireNow={item.EnquireNow} />
   
  );
  return(
    <div className='over'>
      <Carousel 
      autoPlay={true} 
      autoPlaySpeed={6000}  
      showDots={true} 
      infinite={true}
      pauseOnHover={true}
      responsive={responsive}>
       {Product}
       
      </Carousel>

    </div>

  );
};
export default PropertySlider;