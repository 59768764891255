import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';

import Home from './Components/Pages/Home';
import About from './Components/Pages/About';
import Contact from './Components/Pages/Contact';
import PremiumResidentialAdvisory from './Components/SERVICES/Premium Residential Advisory/PremiumResidentialAdvisory';
import CommertialRetailAdvisory from './Components/SERVICES/Commercial & Retail Advisory/CommercialRetailAdvisory';
import CorporateLeasing from './Components/SERVICES/Corporate Leasing/CorporateLeasing';
import EstatePortfolio from './Components/SERVICES/Estate Portfolio Management/EstatePortfolio';
import Connect from './Components/CONNECTUS/Connect';
import ShowMore from './Components/Properties/ShowMore/ShowMore';
import Career from './Components/Career/Career';
import PrivacyPolicy from './Components/Privacy Policy/PrivacyPolicy';
import Terms from './Components/Terms of use/Terms';
import CareerContact from "./Components/CareerContact/CareerContact";
import Blogs from './Components/Blogs/Blogs';
import Blog from './Components/Blogs/Blog';
import Blog2 from './Components/Blogs/Blog2';


function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path='/PremiumResidential' element={<PremiumResidentialAdvisory/>} />
        <Route path='/CommercialSpaces' element={<CommertialRetailAdvisory/>} />
        <Route path='/CorporateLeasing' element={<CorporateLeasing/>} />
        <Route path='/PreLeasedAssets' element={<EstatePortfolio/>} />
        <Route path='/connectwithus' element={<Connect/>} />
        <Route path='/showMore' element={<ShowMore/>} />
        <Route path='/career' element={<Career/>} />
        <Route path='/privacy_policy' element={<PrivacyPolicy/>} />
        <Route path='/terms_of_use' element={<Terms/>} />
        <Route path='/careerContact' element={<CareerContact />} />
        <Route path='/blogs' element={<Blogs />} />
        <Route path='/blog' element={<Blog/>}/>
        <Route path='/Construction_Technology_in_India' element={<Blog2/>}/>
      </Routes>
      </BrowserRouter>
     
    </div>
  );
}

export default App;
