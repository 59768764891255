import classes from "./CommercialRetailAdvisory.module.css";
import Navbar from "../../Navbar/Navbar";
import image from "./Images/CARA.jpg";
import Footer from "../../Footer/Footer";

const CommertialRetailAdvisory=()=>{
    return(
        <>
        <Navbar />
        <div className={classes.head}>
            <h1>Commercial Spaces</h1>
        </div>
        <div className={classes.container}>
            <div className={classes.left}>
                <h1>Introduction</h1>
                <p>As seasoned consultants, we assist both the first-time investors, as well as experienced set of investors seeking the best returns in commercial office spaces or retail units as an investment. Additionally, we also provide excellent options for pre-leased commercial investments with long tenures, ensuring an array of lucrative opportunities</p>
                <p></p>
                 <h4>Why choose PropAssist as your commercial and retail advisory partner? Here are some compelling reasons:</h4>
                 <li><b>Expert Industry Knowledge:</b> Our team of experienced real estate professionals has in-depth knowledge and expertise in the commercial and retail property market. With their finger on the pulse of the industry, they stay updated on market trends, property values, and upcoming locations, ensuring you make informed decisions.</li>
                 <li><b>Personalized Approach:</b> We believe that every business has unique requirements and goals. At PropAssist, we take the time to understand your specific needs, including location preferences, budget, space requirements, and target audience. This allows us to present you with a curated selection of properties that align perfectly with your business objectives.</li>
                 <li><b>Vast Portfolio of Commercial and Retail Spaces:</b> With an extensive portfolio of commercial and retail properties, including office spaces, shops, warehouses, and more, PropAssist offers you a wide range of options to choose from. Whether you're looking for a prime storefront in a bustling shopping district or a spacious office in a corporate hub, we have the perfect space for you.</li>
                
            </div>
            <div className={classes.right}>
                <img src={image} alt="commertial_and _retail_advisory" />
            </div>
        </div>
        <Footer />
        </>
        
        

    );
};
export default CommertialRetailAdvisory;