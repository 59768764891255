import "./Hero.css";
import React, {  useState } from "react";
import { NavLink,Link } from "react-router-dom";
import logo from "./Images/Artboard 20 copy 30.png";




function Hero (){
/*Navbar */
  const [click, setClick] = useState(false);
 

  const handleClick = () => setClick(!click);


  
  
  return(
      <>
      <header>
      <nav className="navbar1">
        <div className="nav-container1">
          <NavLink exact to="/" className="nav-logo1">
           <img src={logo} alt="Propasist" ></img>
          </NavLink>

          <ul className={click ? "nav-menu active" : "nav-menu1"}>
            <li className="nav-item1">
              <NavLink
                exact
                to="/"
                activeClassName="active"
                className="nav-links1"
                onClick={handleClick}
              >
                Home
              </NavLink>
            </li>
            <li className="nav-item1">
              <NavLink
                exact
                to="/about"
                activeClassName="active"
                className="nav-links1"
                onClick={handleClick}
              >
                About Us
              </NavLink>
            </li>

            {/*<li className="nav-item1">
            <div class="dropdown1">
             <button class="dropbtn1">Services</button>
              <div class="dropdown-content1">
                <Link to="/PremiumResidential" className="aref1">Premium Residential</Link>
                <Link to="/CommercialSpaces" className="aref">Commercial Spaces</Link>
                <Link to="/PreLeasedAssets" className="aref">Pre-Leased Assets</Link>
                <Link to="/CorporateLeasing" className="aref">Corporate Leasing</Link>
            </div>
            </div>
  </li>*/}
            
            <li className="nav-item1">
              <NavLink
                exact
                to="/contact"
                activeClassName="active"
                className="nav-links1"
                onClick={handleClick}
              >
                Contact Us
              </NavLink>
            </li>
            
          </ul>
          <div className="nav-icon1" onClick={handleClick}>
            <i className={click ? "fas fa-times" : "fas fa-bars"}></i>
          </div>
        </div>
      </nav>
      
      </header>
      

      <section  className='Home'>
        <div className="content">
          <p className="p1">Welcome to</p> 
          <h1 className="p2">Prop Assist</h1> 
          <p className="p3">Crafting Your Real Estate Vision!</p>
          <p className="p4">Your <span className='p4bg'>search starts now ..</span></p>
          <button className="b1"><Link className="lnkb1" to='/connectwithus'>Connect with us</Link></button>
        </div>
          
      </section>
        </>  
    );
};
export default Hero;
