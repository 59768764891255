import classes from "./Footer.module.css";
import LOGO from "./Images/Artboard 20 copy 31.png";
import image1 from "./Images/facebook.png";
import image2 from "./Images/instagram.png";
import image3 from "./Images/whatsapp.png";
import image4 from "./Images/linkedin.png";
import { Link } from "react-router-dom";


function Footer(){
    return(
        <div className={classes.container} >
            <div className={classes.top}>
                <div className={classes.left}>
                    <img src={LOGO} alt="Prop" />
                    <div className={classes.leftR}>
                        <p>Address : C-Wing, 7th floor, Godrej Eternia, Shivaji Nagar, Pune Maharashtra India - 411005</p>
                        <p>Registered under RERA #A52100047490</p>
                        <div className={classes.iconX}>
                            <Link to="https://www.facebook.com/propassistpune"><img src={image1} alt="prop" /></Link>
                            <Link to="https://www.instagram.com/propassist_2023/"><img src={image2} alt="prop" /></Link> 
                            <Link to="https://www.linkedin.com/company/propassist24by7/"><img src={image4} alt="prop" /></Link>
                        </div>
                    </div>
                </div>
                <div className={classes.right}>
                    <div className={classes.R1}>
                        <h3>Top Cities</h3>
                        <p><Link className={classes.Flinks} to="/showMore">Pune</Link></p>
                        <p>Mumbai</p>
                        <p>Chennai</p>
                    </div>
                    <div className={classes.R1}>
                        <h3>Explore</h3>
                        <p><Link className={classes.Flinks} to="/career">Careers</Link></p>
                        <p><Link className={classes.Flinks} to="/terms_of_use">Terms of Use</Link></p>
                        <p><Link className={classes.Flinks} to="/blogs">Blogs</Link></p>
                    </div>
                    <div className={classes.R1}>
                        <h3>Know More</h3>
                        <p><Link className={classes.Flinks} to="/about">About Us</Link></p>
                        <p><Link className={classes.Flinks} to="/contact">Contact Us</Link></p>
                        <p><Link className={classes.Flinks} to="/privacy_policy">Privacy Policy</Link></p>
                    </div>
                </div>
            </div>
            <div className={classes.bottom}>
                <p><Link className={classes.copyright} to="https://corticawebsolutions.com/">Copyright © 2024 Bluerock Enterprises LLP | Powered by CORTICA </Link></p>
            </div>
        </div>

    );
};
export default Footer;