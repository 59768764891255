import classes from "./WhyUs.module.css";
import image from "./Images/WhyUs.jpg";

const WhyUs=()=>{
    return(
        <div className={classes.container}> 
        <div className={classes.left}>
            <h1>Why Prop Assist</h1>
            <p>At Prop Assist, we redefine the art of real estate advisory, catering exclusively to seeking bespoke solutions</p>
            <p className={classes.points}><i class="fa-solid fa-caret-right"></i> Trusted by Thousands</p>
            <p className={classes.points}><i class="fa-solid fa-caret-right"></i> Wide Range of Properties</p>
            <p className={classes.points}><i class="fa-solid fa-caret-right"></i> Financing Made Easy</p>
        </div>
        </div>

    );
};
export default WhyUs;