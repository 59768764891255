import { useState } from "react";
import classes from "./ContactForm.module.css";
import gif from "./Images/Contact-Us-animation.gif";
import  { useRef } from 'react';
import emailjs from '@emailjs/browser';
import React from "react";
import Select from "react-select";
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

const Options = [
    { value: "Pune", label: "Pune" },
    { value: "Mumbai", label: "Mumbai" },
    { value: "Chennai", label: "Chennai" }
  ];
const PropertyOptions = [
    { value: "Residential Property", label: "Residential Property" },
    { value: "Commercial Property", label: "Commercial Property" },
    { value: "Preleased Property", label: "Preleased Property" },
    { value: "Property on Lease", label: "Property on Lease" },
    { value: "Specific Requirement", label: "Specific Requirement" }
    
  ];
  
  
  const colourStyles = {
    
    control: (base, state) => ({
        ...base,
        border: state.isFocused ? 0 : 0,
        // This line disable the blue border
        boxShadow: state.isFocused ? 0 : 0,
        height: 10,
        width: 400,
        padding: 1,
        margin: 0,
        marginTop:13,
        marginLeft: 0,
        border: "none",
        borderBottom: "1px solid black",
        fontSize: 14,
        fontWeight:650,
        fontfamily:"Helvetica",

        
        '&:hover': {
           border: state.isFocused ? 0 : 0
        }
       
    }),

        
    

    
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      // const color = chroma(data.color);
      console.log({ data, isDisabled, isFocused, isSelected });
      return {
        ...styles,
        backgroundImage: isFocused ? "linear-gradient(to right,#ba0c01,#ed5c01)" : null,
        color: "black",
        color: isFocused ? "white" : null,
        
        };
        
    }
  };


const ContactForm =()=>{
  const [value, setValue] = useState();

  function message (){
    var Name = document.getElementById('name');
    var Email = document.getElementById('email');
    var Location = document.getElementById('location');
    var Mobile = document.getElementById('mobile');
    var Property = document.getElementById('propertytype');
    var Message = document.getElementById('message');
    const Success=document.getElementById('success');
    const Danger=document.getElementById('danger');

    if (Name.value ==='' || Email.value ==='' || Location.value===''||Mobile.value==='' ||
    Property.value==='' ||Message.value==='' ){
        Danger.style.display ='block';
    }
    else{
        setTimeout(()=> {
           Name.value='';
           Email.value='';
           Location.value='';
           Mobile.value='';
           Property.value='';
           Message.value='';
        },2000);
        Success.style.display='block';
    }

    setTimeout(()=>{
     Danger.style.display='none';
     Success.style.display='none';
    },4000)
  }




  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_jgiy1lj', 'template_4l2zi7t', form.current, 'Aw7CyBti3TBdhCREx')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
  };
    return(
        <>
        <div className={classes.container}>
            <div className={classes.headline}>
                <h1>Interested? Enquire Now</h1>
            <div className={classes.left}>
            <form ref={form} onSubmit={sendEmail}>
            <div className={classes.top1}>
                
                <input type="text" placeholder="Enter Full Name" name="name" id="name" required></input>
                <div className={classes.r2}>
                  <input type="email" placeholder="Enter Your Email" name="email" id="email" required />
                  <PhoneInput
                          international
                          countryCallingCodeEditable={false}
                          placeholder="Enter phone number"
                          defaultCountry="US"
                          value={value}
                          onChange={setValue}
                          className={classes.phne}
                          name="mobile"
                          id="mobile"
                          required />
                  
                  </div>

                  <Select
                  className={classes.selct}
                    placeholder="Select Location"
                    label="Single select"
                    options={Options}
                    styles={colourStyles}
                   name="location"
                   id="location"
                  />
                
                <Select
                    className={classes.selct}
                    placeholder="Property Type"
                    label="Single select"
                    options={PropertyOptions}
                    styles={colourStyles}
                    name="propertytype"
                    id="propertytype"
                  />

                
        
                <textarea type="text" placeholder="Your Message" name="message" id="message"></textarea>
                <button type="submit" value="Send" onClick={message}>Connect with us</button>
            </div>
            <div className={classes.messages}>
                <div className={classes.success} id="success">Success ! Thank you For Contacting us </div>
                <div className={classes.danger} id="danger">Feilds Can't be Empty !</div>
            </div>
            </form>
            </div>
            </div>
            <div className={classes.right}>
                <img src={gif} alt="Contact_Prop_Asist" />
            </div>
        </div>
        </>
    );
};
export default ContactForm;