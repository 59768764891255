import { useEffect } from "react";
import Footer from "../Footer/Footer";
import Navbar from "../Navbar/Navbar";
import classes from "./Career.module.css";
import { Link } from "react-router-dom";
import Banner from "./Banner/Banner";
import WhoWe from "./WhoWe";
import WhyP from "./WhyP";



function Career (){
    useEffect(()=>{
    window.scrollTo(0,0);
    },[]);
    return(
     <>
     <Navbar />
     <Banner />
     <WhoWe />
     <WhyP />
         <div className={classes.openings} id="currentOpenings">
        <h1>Current Positions</h1>
        
        <div className={classes.card}>
            <h3>Sales Manager</h3>
            <div className={classes.r1}>
                <p><i class="fa-solid fa-location-dot"></i> Pune</p>
                <p><i class="fa-regular fa-user"></i> Full Time</p>
                <p><i class="fa-solid fa-list"></i> 2-5 years Experience</p>
            </div>
            <li>Manage a sales team and provide leadership, training and coaching</li>
            <li>Develop a sales strategy to achieve organizational sales goals and revenues</li>
            <li>Set individual sales targets with sales team</li>
            <li>Work on sales scripts with agents</li>
            <li>Track, collate and interpret sales figures and reporting</li>
            <li>Ensure members of the sales team have the necessary resources to perform properly</li> 
            <li>Plan and direct sales team training</li>
            <li>Control expenses and monitor budgets</li>
            <button className={classes.btn}><Link className={classes.Alinks} to="/careerContact">Apply Now</Link></button>
        </div>

        <div className={classes.card}>
            <h3>Pre-sales Advisors</h3>
            <div className={classes.r1}>
                <p><i class="fa-solid fa-location-dot"></i> Pune</p>
                <p><i class="fa-regular fa-user"></i> Full Time</p>
                <p><i class="fa-solid fa-list"></i> 0-2 years Experience</p>
            </div>
            <li>Calling prospective customers & explaining projects</li>
            <li>Carrying out site visits</li>
            <li>Identify prospective clients</li>
            <li>Follow up with qualified leads to close the deals</li>
            <li>Price negotiations for final deals</li>
            <li>Follow up with qualified leads to close the deals</li> 
            
            <button className={classes.btn}><Link className={classes.Alinks} to="/careerContact">Apply Now</Link></button>
        </div>
     </div>
     <Footer />
     </>
    );
};
export default Career;