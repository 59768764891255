import classes from "./Residential.module.css";
import image1 from "./Images/keys.png";
import image2 from "./Images/bell.png";
import image3 from "./Images/menu.png";
import image4 from "./Images/for-sale.png";







function Residential () {
    return(
      <div className={classes.container} >
        <h1>Our Services</h1>
        <div className={classes.main}>
          <div className={classes.box} >
            <div className={classes.image}><img src={image1} alt="Luxurious Residential" /></div>
            <h2>Premium Residential</h2>
            <p>Discover exquisite homes tailored to your luxury living aspirations!</p>
          </div>
          <div className={classes.box} >
            <div className={classes.image}><img src={image2} alt="Commercial Office SpacesLuxurious Residential" /></div>
            <h2 >Commercial Spaces</h2>
            <p>Explore tailored solutions for your business space needs with us.</p>
          </div>
          <div className={classes.box}  >
            <div className={classes.image}><img src={image3} alt="pre impact commercial space" /></div>
            <h2>Pre-Leased Assets</h2>
            <p>Invest effortlessly with our pre-leased assets for consistent returns.</p>
          </div>
          <div className={classes.box}  >
            <div className={classes.image}><img src={image4} alt="Corporate Leasing" /></div>
            <h2>Corporate Leasing</h2>
            <p>Secure the ideal corporate space for your business with our leasing expertise.</p>
          </div>
          </div>
          
        </div>
       
    );
};
export default Residential;