import Navbar from "../Navbar/Navbar";
import Contact1 from "../CONTACT/Contact1";
import Footer from "../../Components/Footer/Footer";
import { useEffect } from "react";
import WatsappFloat from "../WatsappFloat";


function Contact (){
    useEffect(()=>{
        window.scrollTo(0,0);
    },[]);
    return(
    <>
    <Navbar/>
    <Contact1 />
    <WatsappFloat />
    <Footer />
    </>
    );
};
export default Contact;