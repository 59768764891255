import classes from "./EstatePortfolio.module.css";
import Navbar from "../../Navbar/Navbar";
import image from "./Image/pre.jpg";
import Footer from "../../Footer/Footer";

const EstatePortfolio=()=>{
    return(
        <>
        <Navbar />
        <div className={classes.head}>
            <h1>Pre-Leased Assets</h1>
        </div>
        <div className={classes.container}>
            <div className={classes.left}>
                <h1>Introduction</h1>
                <p>Our expert management maximizes your investments, ensuring lucrative returns and long-term prosperity.</p>
                <h4>Why choose Prop Assist for your pre-leased asset investment needs?</h4>
                <li><b>Prime Locations:</b> Our handpicked properties are strategically located in high-growth areas, ensuring a strong demand for rentals. Whether you're interested in commercial spaces, residential apartments, or even retail outlets, we have options that cater to every investment preference.</li>
                <li><b>Guaranteed Income:</b> Say goodbye to the uncertainty of vacant properties. With pre-leased assets, you can enjoy a steady stream of rental income from day one. Our diligent screening process ensures tenants with robust creditworthiness, minimizing the risk of payment defaults.</li>
                <li><b>Transparent & Hassle-Free:</b> Transparency is at the heart of our operations. We provide comprehensive information about each property, including rental agreements, tenure, and existing tenants. Our dedicated team is also ready to assist you throughout the investment journey, making the process hassle-free and seamless.</li>
                <li><b>Capital Appreciation:</b> Apart from the rental income, pre-leased assets also offer the potential for capital appreciation. As property values appreciate over time, your investment could grow significantly, providing you with substantial returns in the long run.</li>
                
                
            </div>
            <div className={classes.right}>
                <img src={image} alt="commertial_and _retail_advisory" />
            </div>
        </div>
        <Footer />
        </>
        
        

    );
};
export default EstatePortfolio;