
import AboutHead2 from "../ABOUT/AboutHead2/AboutHead2";
import AboutHead3 from "../ABOUT/AboutHead3/AboutHead3";
import Team from "../ABOUT/Team/Team";
import WhyUs from "../ABOUT/WhyUs/WhyUs";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import Mission from "../ABOUT/MissionVision/Mission";
import { useEffect } from "react";
import WatsappFloat from "../WatsappFloat";
function About (){
    useEffect(()=>{
        window.scrollTo(0,0);
    },[]);
    return(
    <>
    <Navbar />
    <AboutHead2 />
    <Mission />
    <AboutHead3 />
    <WhyUs />
    <Team />
    <WatsappFloat/>
    <Footer />
    </>
    );
};
export default About;