import Hero from "../Hero/Hero";

import Residential from "../Residential/Residential";

import Whychoose from "../Whychoose/Whychoose";
import Footer from "../Footer/Footer";

import TestimonialsSlider from "../Testimonials/TestimonialsSlider";
import TestimonialsHead from "../Testimonials/TestimonialsHead";
import FeaturePropertyHeader from "../Properties/FeaturePropertyHeader";
import PropertySlider from "../Properties/PropertySlider";
import Association from "../ASSOCIATION/Association";
import AssociationSlider from "../ASSOCIATION/AssociationSlider";
import { useEffect } from "react";
import WatsappFloat from "../WatsappFloat";

function Home (){
    useEffect(()=>{
        window.scrollTo(0,0);
    },[]);
    return(
    <>
    
    <Hero/>
    <Residential />
    <Whychoose />
    <FeaturePropertyHeader/>
    <PropertySlider />
    
    <TestimonialsHead />
    <TestimonialsSlider />
    <Association/>
    <AssociationSlider/>
    <WatsappFloat/>
    <Footer />
    </>
    );
};
export default Home;