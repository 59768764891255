import classes from "./Association.module.css";

function Association(){
    return(
       <>
       <div className={classes.container}>
        <h1>Our Association</h1>
       </div>
       </>
    );
};
export default Association;