import classes from "./Contact1.module.css";


function Contact1(){
    return(
        <div className={classes.container}>
            <div className={classes.left}>
                <div className={classes.c1}>
                    <div className={classes.c1l}><i class="fa-solid fa-location-dot"></i></div>
                    <div className={classes.c1r}>
                        <div className={classes.r1}>Address</div>
                        <div className={classes.r2}> C-Wing, 7th floor, Godrej Eternia, Shivaji Nagar, Pune Maharashtra India - 411005</div>
                    </div>
                    </div>
                <div className={classes.c1}>
                <div className={classes.c1l}><i class="fa-solid fa-phone"></i></div>
                    <div className={classes.c1r}>
                        <div className={classes.r1}>Phone No</div>
                        <div className={classes.r4}>+91 9860099550</div>
                    </div>
                </div>
                <div className={classes.c1}>
                <div className={classes.c1l}><i class="fa-solid fa-envelope"></i></div>
                    <div className={classes.c1r}>
                        <div className={classes.r1}>Email Us</div>
                        <div className={classes.r4}>sales@propassist.in</div>
                        
                    </div>
                </div>
            </div>
            
        <div className={classes.right}><iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3782.8770264040295!2d73.84963742389822!3d18.53445846875246!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2c0866d342691%3A0xd259cd5e4e8bd8cb!2sGodrej%20Eternia%2C%20Corporation%20Colony%2C%20Shivajinagar%2C%20Pune%2C%20Maharashtra%20411005!5e0!3m2!1sen!2sin!4v1694680646274!5m2!1sen!2sin"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe></div>
        </div>
    );
};
export default Contact1;