import classes from "./Mission.module.css";
import image1 from "./Images/flag.png";
import image2 from "./Images/vision.png";
import image3 from "./Images/success.png";


const Mission =()=>{
    return(
    <>
    <div className={classes.main}>
          <div className={classes.box} >
            <div className={classes.image}><img src={image1} alt="Luxurious Residential" /></div>
            <h2>Our Mission</h2>
            <p>Empower clients through personalized, seamless real estate support.</p>
          </div>
          <div className={classes.box} >
            <div className={classes.image}><img src={image2} alt="Commercial Office SpacesLuxurious Residential" /></div>
            <h2 >Our Vision</h2>
            <p>Redefine the industry with innovative technology and exceptional service.</p>
          </div>
          <div className={classes.box}  >
            <div className={classes.image}><img src={image3} alt="pre impact commercial space" /></div>
            <h2>Our Values</h2>
            <p>Integrity, transparency, client-centricity, trust, and lasting relationships.</p>
          </div>
          </div>
    </>
        );
};
export default Mission;