import classes from "./ShowMore.module.css";
import Navbar from "../../Navbar/Navbar";
import Footer from "../../Footer/Footer";
import data from "../PropertyData.json";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";




function ShowMore(){
  const [tab,setTab]=useState(1);
  const [all,setAll]=useState(true);

  const allHandle=()=>{
    setAll(!all);
    setTab(null);
  };

  function activeTab(tabNumber){
    setAll(false);
    setTab(tabNumber);
    
  }

 
  const apartment = data.filter((property)=>property.type === "Apartment");
  const officeSpaces = data.filter((property)=>property.type === "Office Spaces");
  const preleasedAsset = data.filter((property)=>property.type === "Preleased Asset");
  const availableOnLease = data.filter((property)=>property.type === "Available on Lease");

  const fill =  tab===2 ? apartment : tab===3 ? officeSpaces : tab===4 ? preleasedAsset : tab===5 ? availableOnLease: null;
  
  useEffect(()=>{
    window.scrollTo(0,0)
  },[]);
    return(
        <>
        <Navbar />
         
         <section className={classes.container}>
            <div className={classes.top}>
                <button className={classes.btn} onClick={allHandle} 
                 style={{background:tab===1 ? "#204C79" : "" ,color: tab===1 ? "white":"" , border: tab===1 ? "none" : ""}}>All</button>
                <button className={classes.btn} onClick={()=>{activeTab(2) }}
                style={{background:tab===2 ? "#204C79" : "" ,color: tab===2 ? "white":"" , border: tab===2 ? "none" : ""}}>Apartments/Villas</button>
                <button className={classes.btn} onClick={()=>{activeTab(3)}}
                style={{background:tab===3 ? "#204C79" : "" ,color: tab===3 ? "white":"" , border: tab===3 ? "none" : ""}}>Office Spaces</button>
                <button className={classes.btn} onClick={()=>{activeTab(4)}}
               style={{background:tab===4 ? "#204C79" : "" ,color: tab===4 ? "white":"" , border: tab===4 ? "none" : ""}}>Preleased Properties</button>
                <button className={classes.btn} onClick={()=>{activeTab(5)}}
                style={{background:tab===5 ? "#204C79" : "" ,color: tab===5 ? "white":"" , border: tab===5 ? "none" : ""}}>Properties on Lease</button>
            </div>
            <div className={classes.bottom}>
              {fill && fill.map((value)=>{
                return(
                  <div className={classes.row}>
            
                    <div className={classes.box}>
                    <div className={classes.image}>
                    <img src={value.image} alt="" />
                    <div className={classes.bottompart}>
                    <p className={classes.l1A}>{value.type}</p>
                    <h3 className={classes.heading}>{value.name}</h3>
                    <p className={classes.l1B}><i class="fa-solid fa-map-pin"></i> {value.location}</p>
                    <div className={classes.rowflex}>
                    <p className={classes.l1B}><b>Beds -</b> {value.beds}</p>
                    
                    <p className={classes.l1B}><b>SqFt -</b> {value.sqft}</p>
                    </div>
                    
                    <div className={classes.rowflex1}>
                    <p className={classes.POR1}><Link  className={classes.POR1} to='/connectwithus'>{value.POR}</Link></p>
                    <p className={classes.POR2}>{value.Price}</p> 
                    <p className={classes.POR3}><Link  className={classes.POR3} to='/connectwithus'>{value.EnquireNow}</Link></p>
                    </div>
                    </div>
                </div>
            </div>

        </div>
                )
              })}
              
            
            </div>
         </section>


         <section>
         <div className={classes.bottom}>
              {all && data.map((value)=>{
                return(
                  <div className={classes.row}>
            
                    <div className={classes.box}>
                    <div className={classes.image}>
                    <img src={value.image} alt="" />
                    <div className={classes.bottompart}>
                    <p className={classes.l1A}>{value.type}</p>
                    <h3 className={classes.heading}>{value.name}</h3>
                    <p className={classes.l1B}><i class="fa-solid fa-map-pin"></i> {value.location}</p>
                    <div className={classes.rowflex}>
                    <p className={classes.l1B}><b>Beds -</b> {value.beds}</p>
                    
                    <p className={classes.l1B}><b>SqFt -</b> {value.sqft}</p>
                    </div>
                    
                    <div className={classes.rowflex1}>
                    <p className={classes.POR1}><Link  className={classes.POR1} to='/connectwithus'>{value.POR}</Link></p>
                    <p className={classes.POR2}>{value.Price}</p> 
                    <p className={classes.POR3}><Link  className={classes.POR3} to='/connectwithus'>{value.EnquireNow}</Link></p>
                    </div>
                    </div>
                </div>
            </div>

        </div>
                )
              })}
              
            
            </div>
         </section>

        <Footer />
        </>

    );
};
export default ShowMore;