import classes from "./Whychoose.module.css";
import image1 from "./Images/friendship.png";
import image2 from "./Images/choices.png";
import image3 from "./Images/analytics.png";


function Whychoose(){
    
    
    return(
        <div className={classes.container}>
            <h1 >Why Choose Us</h1>
            <p >At Prop Assist, we understand that choosing the right real estate agency is a crucial decision that can significantly impact your property buying, selling, or renting experience. Here are some compelling reasons why you should choose us!</p>
            <div className={classes.row} >
                
                <div className={classes.box}>
                    <div className={classes.image}>
                        <img src={image1} alt="why choose prop s" />
                    </div>
                    <h2 >Trusted By Thousands</h2>
                </div>
            
           
                <div className={classes.box}>
                    <div className={classes.image}>
                        <img src={image2} alt="why choose prop asist" />
                    </div>
                    <h2>Wide Range Of Properties</h2>
                </div>
            
            
                <div className={classes.box}>
                    <div className={classes.image}>
                    <img src={image3} alt="why choose prop asist" />
                    </div>
                    <h2 >Financing Made Easy</h2>
                </div>
                </div>
        </div>
       
    );
};
export default Whychoose;