import Classes from "./CareerContact.module.css";
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import image from "../CONTACT/Images/Contact-Us-animation.gif";


function Career(){
    const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_5nfxqsf', 'template_edy6cuq', form.current, 'gy9MpvnsUTCnW6YHW')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
  }; 
   function send (){
    const Name= document.getElementById("name");
    const Email= document.getElementById("email");
    const Mobile= document.getElementById("mobile");
    const Subject= document.getElementById("subject");
    const Message= document.getElementById("message");
    const Success= document.getElementById("success");
    const Danger= document.getElementById("danger");
    const Post = document.getElementById("post");

    if (Name.value==="" || Email.value==="" || Mobile.value==="" ||Subject.value===""
    ||Message.value==="" || Post.value===""){
        Danger.style.display='block';
    }
    else{
        setTimeout(()=>{
        Name.value="";
        Email.value="";
        Mobile.value="";
        Subject.value="";
        Message.value="";
        Post.value="";
        },2000);
        Success.style.display='block';
    }
    setTimeout(()=>{
     Danger.style.display='none';
     Success.style.display='none';
    },4000)

   }

    return(
        <>
        <Navbar />
        <h1 className={Classes.heading}>Come Grow With Us</h1>
        <div className={Classes.container}>
            <div className={Classes.left}>
                <form ref={form} onSubmit={sendEmail} className={Classes.Form}>
                    <input type="text" placeholder="Enter Your Full Name" name="name" id="name" required/>
                    <input type="email" placeholder="Enter Your Email" name="email" id="email" required/>
                    <input type="number" placeholder="Enter Mobile" name="mobile" id="mobile" required/>
                    <select name="post" id="post" >
                        <option >Select Post Applying For</option>
                        <option>Sales Manager</option>
                        <option>Pre-sales Advisors</option>
                    </select>
                    <input type="text" name="subject" id="subject" placeholder="Subject" required></input>
                    <textarea placeholder="Write Message" name="message" id="message" />
                    <button type="submit" value="Send" onClick={send} >Submit</button>
                    <div className={Classes.message}>
                        <p className={Classes.success} id="success">Message Sent Successfully </p>
                        <p className={Classes.daanger} id="danger">Fields Can't Empty </p>
                    </div>
                </form>
            </div>
            <div className={Classes.right}>
                <img src={image} alt="Prop Assist"></img>
            </div>
        </div>
        <Footer />
        </>
    );
};
export default Career;