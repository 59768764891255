import classes from "./FeaturePropertyHeader.module.css";
import { Link } from "react-router-dom";

function FeaturePropertyHeader (){
    return(
        <div className={classes.container}>
          <div className={classes.left}>
          <h2>Featured Properties</h2>
          <p>Exclusive real estate properties from renowned developers!</p>
        </div>
        <div className={classes.right}>
          <Link to="/showMore" className={classes.showAll}>View All</Link>
        </div>
        </div>
    );
};
export default FeaturePropertyHeader;