import classes from "./WhyP.module.css";
import image1 from "./Banner/Image/BG.png";
import image2 from "./Banner/Image/54950.jpg";
import image3 from "./Banner/Image/859_win_together.jpg";


function WhyP(){
    
    
    return(
        <div className={classes.container}>
            <h1 >Why Choose Prop Assist</h1>
            <div className={classes.row} >
                
                <div className={classes.box}>
                    <div className={classes.image}>
                        <img src={image1} alt="why choose prop s" />
                    </div>
                    <h2 >Limitless Growth Opportunities</h2>
                </div>
            
           
                <div className={classes.box}>
                    <div className={classes.image}>
                        <img src={image2} alt="why choose prop asist" />
                    </div>
                    <h2>Supportive Work Environment</h2>
                </div>
            
            
                <div className={classes.box}>
                    <div className={classes.image}>
                    <img src={image3} alt="why choose prop asist" />
                    </div>
                    <h2 >Competitive Compensation</h2>
                </div>
                
                </div>
        </div>
       
    );
};
export default WhyP;