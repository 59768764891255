import classes from "./Testimonials.module.css";



const Testimonials = (props) =>{
    return(
      <div className={classes.container}>
        <div className={classes.card}>
            <h4>{props.name}</h4>
            <img src={props.star} alt="5/5"/>
            <p>{props.text}</p>
        </div>
      
      </div>
    );
};
export default Testimonials;
