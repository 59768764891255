import classes from "./AboutHead2.module.css";


const AboutHead2=()=>{
    return(
        <div className={classes.container}>
            <div className={classes.left}>
               <h1>Welcome to Prop Assist</h1>
               <h3> Your Trusted Real Estate Consulting Partner</h3>
               <p>At Prop Assist, we are driven by a deep passion for fulfilling people's aspirations with safe and secure residential, commercial, and plot investments. We firmly believe in the enduring value of real estate, and our meticulous planning, extensive research, and strategic insights ensure that we unveil the most exquisite and unique properties for our valued clients.
                <br></br> <br></br>With Prop Assist, you can trust in our unparalleled professionalism and dedication to guide you towards exceptional investments in every step of your real estate journey.
                </p>
            </div>
        </div>

    );
};
export default AboutHead2;