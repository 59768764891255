import classes from "./AboutHead3.module.css";
import image from "../AboutHead2/Images/output-onlinegiftools.gif";

const AboutHead3=()=>{
    return(
        <div className={classes.container}>
            <div className={classes.left}>
               <h1>What Drive Us ?</h1>
               <p>Prop Assist is a premier end-to-end real estate services company, with a strong presence in both Pune and Mumbai. We are dedicated to delivering bespoke solutions to our valued customers, ranging from property scouting, inventory selection to securing the best offers and providing unparalleled post-purchase assistance.
                <br></br><br></br>With unparalleled expertise and an unwavering commitment to excellence, we stand as your trusted partner, ensuring your unique investment aspirations are met with precision and professionalism.
               </p>
            </div>
        </div>


    );
};
export default AboutHead3;