import Navbar from "../Navbar/Navbar";
import ContactForm from "../CONTACT/ContactForm";
import Footer from "../Footer/Footer";
import { useEffect } from "react";

const Connect=()=>{
    useEffect(()=>{
     window.scrollTo(0,0)
    },[]);
    return(
       <>
       <Navbar></Navbar>
       <ContactForm></ContactForm>
       <Footer></Footer>
       </>
    );
};
export default Connect;