import React from 'react'
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import logo from "./Hero/Images/Artboard 20 copy 30.png";
import "./Watsapp.css";

export default function WatsappFloat() {

  return (
      <FloatingWhatsApp 
                phoneNumber="7709622218"
                accountName="Prop Assist"
                avatar={logo}
                chatMessage="Hello,
                Thanks for sharing your valuable time with us. We would like to discuss on a couple of really great property investment options across Pune, Mumbai & Chennai.
                
                Let's us know a good time to connect with you over a call?"
                allowEsc
                allowClickAway
                notification
                notificationSound 
                className='over'/>
  )
}