import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import classes from "./Association.module.css";

import image1 from "./Images/Kohinoor Group Pune Logo.jpg";
import image2 from "./Images/Mittal Brothers Pvt Ltd logo.png";
import image3 from "./Images/Panchshil Realty Logo.jpg";
import image4 from "./Images/Shapoorji_Pallonji_Group_logo.svg.png";
import image5 from "./Images/bramhacorp-logo-vector.png";
import image6 from "./Images/kasturi housing logo.jpg";
import image7 from "./Images/reliance-retail-logo.png";

export default class AssociationSlider extends Component {
  render() {
    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      autoplaySpeed: 2000,
      slidesToShow: 4,
      slidesToScroll: 1,
      initialSlide: 0,
      autoplay:true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        }
      ]
    };
    return (
      <div className={classes.main}>
        
        <Slider {...settings}>
          <div>
            <img className={classes.image} src={image1} alt="PropAssist" />
          </div>
          <div>
          <img className={classes.image} src={image2} alt="PropAssist" />
          </div>
          <div>
          <img className={classes.image} src={image3} alt="PropAssist" />
          </div>
          <div>
          <img className={classes.image} src={image4} alt="PropAssist" />
          </div>
          <div>
          <img className={classes.image} src={image5} alt="PropAssist" />
          </div>
          <div>
          <img className={classes.image} src={image6} alt="PropAssist" />
          </div>
          <div>
          <img className={classes.image} src={image7} alt="PropAssist" />
          </div>

        </Slider>
      </div>
    );
  }
}