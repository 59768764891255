import React from 'react'
import classes from "./Blogs.module.css"
import P1 from "./Image/f.jpeg";
import P2 from "./Image/blog2.jpg";
import Navbar from '../Navbar/Navbar'
import Footer from '../Footer/Footer'
const Blogs = () => {
  return (
   <>
   <Navbar />
    <div className={classes.blogs}>


       <h1 className={classes.heding}> Blogs </h1>

            <br/>

        <div className={classes.bgs}>

<div className={classes.box}>
      <div><img src={P1} alt="Prop Assist Blog"  className={classes.pi} /></div>
      <h5 className={classes.ph} > Pune Real Estate Market Forecast & Trends 2024: Exploring Future Opportunities and insights</h5>
      <p className={classes.dt}>February 09, 2024</p>
   <  br/>
      <a href="/Blog" className={classes.btn} >READ MORE</a>
</div>

<div className={classes.box}>
      <div><img src={P2} alt="Prop Assist Blog"  className={classes.pi} /></div>
      <h5 className={classes.ph} >Construction Technology in India: Building the Future</h5>
      <p className={classes.dt}>March 04, 2024</p>
   <  br/>
      <a href="/Construction_Technology_in_India" className={classes.btn} >READ MORE</a>
</div>


    

    

     
</div>


        </div>
      <Footer/>
        </>
  )
}

export default Blogs