import classes from "./PremiumResidentialAdvisory.module.css";
import Navbar from "../../Navbar/Navbar";
import image from "./Images/PRA.jpg";
import Footer from "../../Footer/Footer";

const PremiumResidentialAdvisory=()=>{
    return(
        <>
        <Navbar />
        <div className={classes.head}>
            <h1>Premium Residential</h1>
        </div>
        <div className={classes.container}>
            <div className={classes.left}>
                <h1>Introduction</h1>
                <p>Discover your dream home with our expert guidance, tailored to your unique preferences, and make your luxury living aspirations a reality.</p>
                <p>At PropAssist, we understand that your dream home is more than just a structure. It's a reflection of your lifestyle, aspirations, and personal taste. That's why we go above and beyond to provide expert guidance tailored to your unique needs and preferences.</p>
                 <h4>Why choose PropAssist as your residential advisory partner? Here's why:</h4>
                 <li><b>Unparalleled Expertise:</b> Our team of seasoned real estate professionals possesses extensive knowledge and experience in the luxury residential market. We stay up-to-date with current trends, market conditions, and exclusive listings, ensuring we provide you with the most accurate and relevant information.</li>
                 <li><b>Personalized Approach:</b> We believe in creating lasting relationships with our clients. Our dedicated advisors take the time to understand your vision, requirements, and budget, enabling us to curate a tailored selection of properties that align perfectly with your goals and lifestyle.</li>
                 <li><b>Vast Portfolio of Premium Properties:</b> As experts in the luxury real estate segment, PropAssist has access to an extensive portfolio of exclusive properties, including luxury homes, penthouses, villas, and mansions in prime locations. Whether you seek a lavish beachfront retreat or a sophisticated urban penthouse, we have your dream property waiting.</li>
                 <li><b>Transparent and Professional Service:</b> With PropAssist, you can expect complete transparency throughout the buying process. We guide you every step of the way, from selecting the ideal property to negotiating the best terms, ensuring a smooth and hassle-free experience. Your satisfaction is our top priority.</li>
            </div>
            <div className={classes.right}>
                <img src={image} alt="Premium_residential_advisory" />
            </div>
        </div>
        <Footer />
        </>
        
        

    );
};
export default PremiumResidentialAdvisory;