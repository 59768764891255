import { Link } from "react-router-dom";
import classes from "./Properties.module.css";


function Property(props){
    return(
        <>
        <div className={classes.row}>
            
                    <div className={classes.box}>
                    <div className={classes.image}>
                    <img src={props.image} alt="" />
                    <div className={classes.bottompart}>
                    <p className={classes.l1A}>{props.type}</p>
                    <h3 className={classes.heading}>{props.name}</h3>
                    <p className={classes.l1B}><i class="fa-solid fa-map-pin"></i> {props.location}</p>
                    <div className={classes.rowflex}>
                    <p className={classes.l1B}><b>Beds -</b> {props.beds}</p>
                    
                    <p className={classes.l1B}><b>SqFt -</b> {props.sqft}</p>
                    </div>
                    
                    <div className={classes.rowflex1}>
                    <p className={classes.POR1}><Link  className={classes.POR1} to='/connectwithus'>{props.POR}</Link></p>
                    <p className={classes.POR2}>{props.Price}</p> 
                    <p className={classes.POR3}><Link  className={classes.POR3} to='/connectwithus'>{props.EnquireNow}</Link></p>
                    </div>
                    </div>
                </div>
            </div>

        </div>
        </>
    );
};
export default Property;