import classes from "./CorporateLeasing.module.css";
import Navbar from "../../Navbar/Navbar";
import image from "./Images/CL.jpeg";
import Footer from "../../Footer/Footer";

const CorporateLeasing=()=>{
    return(
        <>
        <Navbar />
        <div className={classes.head}>
            <h1>Corporate Leasing</h1>
        </div>
        <div className={classes.container}>
            <div className={classes.left}>
                <h1>Introduction</h1>
                <p>Unlock the potential of your business with our top-notch Corporate Leasing services. From big Indian retail brands to coworking companies and esteemed corporates, our tailored solutions ensure seamless leasing experiences, positioning your business for unrivaled success.</p>
                
                 <h4>Why choose PropAssist for your corporate leasing needs? Here's what sets us apart:</h4>
                 <li><b>Extensive Market Knowledge:</b> Our team of seasoned real estate professionals possesses extensive knowledge of the commercial leasing market. We stay up-to-date with current trends, prime office locations, and market conditions, ensuring we provide you with accurate and relevant information to make informed decisions.</li>
                 <li><b>Tailored Solutions:</b> We understand that every business has unique requirements. At PropAssist, we take the time to understand your specific needs, including office size, location preferences, amenities, and budget. This allows us to present you with a curated selection of corporate spaces that align perfectly with your business objectives.</li>
                 <li><b>Seamless Leasing Process:</b> At PropAssist, we understand that time is of the essence for businesses. We streamline the corporate leasing process, handling negotiations, lease agreements, and paperwork on your behalf. Our dedicated team ensures a smooth and efficient transition into your new office space, allowing you to focus on what matters most—growing your business.</li>
                
            </div>
            <div className={classes.right}>
                <img src={image} alt="commertial_and _retail_advisory" />
            </div>
        </div>
        <Footer />
        </>
        
        

    );
};
export default CorporateLeasing;