import classes from "./TestimonialsHead.module.css";

const TestimonialsHead=()=>{
    return(
        <div className={classes.container}>
           <h2>Client Testmonials</h2>
        </div>
    );

};
export default TestimonialsHead;