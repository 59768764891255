import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';




import Testimonials from "./Testimonials";

import star5 from "./Images/5star.png";
import star4 from "./Images/4star.png";

function TestimonialsSlider(){
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 2,
      slidesToSlide: 1
    },
    desktop: {
      breakpoint: { max: 3000, min: 1000 },
      items: 1.5,
      slidesToSlide: 1
    },
    desktop1: {
      breakpoint: { max: 1000, min: 768 },
      items: 1.5,
      slidesToSlide: 1
    },
    tablet: {
      breakpoint: { max: 768, min: 500 },
      items: 2,
      slidesToSlide: 1
    },
    mobile: {
      breakpoint: { max: 500, min: 0 },
      items: 1,
      slidesToSlide: 1
    }
  };

  return(
    <div className='over'>
      <Carousel 
      autoPlay={true} 
      autoPlaySpeed={4500}  
      showDots={false} 
      infinite={true}
      pauseOnHover={true}
      responsive={responsive}>
       
            <div>
             <Testimonials  
             name ="Mr & Mrs Mehta" 
             star={star5} 
             text="I am truly grateful to the Prop Assist team for their professionalism and dedication. They went above and beyond to find the perfect property for us, considering all our requirements. The entire experience was smooth and stress-free, leaving us highly satisfied!"  />
            </div>
            <div>
            <Testimonials  
             name ="M/s Kudale Agro Foods" 
             star={star5} 
             text="Outstanding service! The Prop Assist team is truly exceptional. They understood our preferences perfectly and showed us some incredible options. They made the buying process effortless, and we are thrilled with our new home. Highly recommended!"  />
            </div>
            <div>
            <Testimonials  
             name ="Mr Jat" 
             star={star4} 
             text="Prop Assist's team demonstrated great expertise and support throughout the buying process. They were knowledgeable and responsive, guiding us effectively. Our overall experience was excellent, with just a minor hiccup that they handled professionally."  />
            </div>
            <div>
            <Testimonials  
             name ="Mr Lakhotia" 
             star={star4} 
             text="Working with Prop Assist was a pleasure. Their team was attentive and communicative, addressing our concerns promptly. They helped us find a fantastic property that we are delighted with. Overall, it was a great experience!"  />
            </div>
            <div>
            <Testimonials  
             name ="Mr Shaikh " 
             star={star4} 
             text="I had a great experience with Prop Assist. The team was knowledgeable and patiently answered all my questions. They provided valuable support throughout the process, making it a smooth journey. I highly recommend their services!"  />
            </div>
            <div>
            <Testimonials  
             name ="Mr More" 
             star={star5} 
             text="Highly impressed with Prop Assist's team. They are experts in the real estate market and helped us find a property that exceeded our expectations. From start to finish, their professionalism and efficiency were commendable. We are delighted with their services!"  />
            </div>
            <div>
            <Testimonials  
             name ="Mr Gohil" 
             star={star5} 
             text="The Prop Assist team was wonderful to work with. They were attentive and provided valuable insights during the property search. Our experience with them was positive, and we are happy with the outcome. Thank you, Prop Assist!"  />
            </div>
       
      </Carousel>

    </div>

  );
};
export default TestimonialsSlider;