import classes from "./WhoWe.module.css";
import image from "./Banner/Image/5995342.jpg";

function WhoWe(){
    return(
        <div className={classes.container}>
            <div className={classes.left}>
                <img src={image} alt="prop Assist" />
            </div>
            <div className={classes.right}>
                <h1>Who We Are ?</h1>
                <p>At Prop Assist, we are not just another real estate company. We are a passionate team dedicated to making a difference in people's lives. With a solid foundation of integrity and professionalism, we strive to exceed client expectations and provide them with the smoothest real estate experience possible. Whether it's buying, selling, or renting, we are there every step of the way.</p>
            </div>
        </div>
    );
};
export default WhoWe;