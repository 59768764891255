import classes from "./Banner.module.css";
import image from "./Image/7566-PhotoRoom.png-PhotoRoom.png";
import { Link } from "react-scroll";
function Banner(){
    return(
        <>
        <div className={classes.container}>
            <div className={classes.left}>
                <p className={classes.p1}>CAREERS AT PROP ASSIST</p>
                <h1 className={classes.p2}>Work with us</h1>
                <p className={classes.p3}>We are seeking talented professionals who embody our core values of integrity, commitment, and excellence.</p>
                <button className={classes.btn}><Link className={classes.btnlnk} to="currentOpenings" spy={true} smooth={true} >View Current Openings</Link></button>
            </div>
            <div className={classes.right}>
                <img src={image} alt="prop assist" />
            </div>
        </div>
        </>
    );
};
export default Banner;