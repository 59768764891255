import React, { useState } from "react";
import { NavLink,Link } from "react-router-dom";
import "./Navbar.css";
import logo from "./Images/Artboard 20 copy 31.png";


function Navbar() {
  /*Navbar */
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);

  
  
  return(
      <>
      <header>
      <nav className="navbar">
        <div className="nav-container">
          <NavLink exact to="/" className="nav-logo">
           <img src={logo} alt="Propasist" ></img>
          </NavLink>

          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li className="nav-item">
              <NavLink
                exact
                to="/"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                Home
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/about"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                About Us
              </NavLink>
            </li>
            {/* <li className="nav-item">
            <div class="dropdown">
             <button class="dropbtn">Services</button>
              <div class="dropdown-content">
                <Link to="/PremiumResidential" className="aref1">Premium Residential</Link>
                <Link to="/CommercialSpaces" className="aref">Commercial Spaces</Link>
                <Link to="/PreLeasedAssets" className="aref">Pre-Leased Assets</Link>
                <Link to="/CorporateLeasing" className="aref">Corporate Leasing</Link>
            </div>
            </div>
            </li>*/}
            
            
            <li className="nav-item">
              <NavLink
                exact
                to="/contact"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                Contact Us
              </NavLink>
            </li>
            
          </ul>
          <div className="nav-icon" onClick={handleClick}>
            <i className={click ? "fas fa-times" : "fas fa-bars"}></i>
          </div>
        </div>
      </nav>
      
      </header>
      
   </>
  );
}

export default Navbar;